// General Imports
import React, { createContext, useState } from 'react';

export const PantryLLCContext = createContext();

const Provider = (props) => {
    const [modalShowing, setModalShowing] = useState(false);

    const properties = {
        modalShowing,
        setModalShowing
    }

    return (
        <PantryLLCContext.Provider value={properties}>
            {props.children}
        </PantryLLCContext.Provider>
    )
}

export default Provider;