// General Imports
import React from 'react';

// Styles Import
import '../Styles/styles.scss';

const Footer = () => {
    return (
        <div className="footerDiv">
            <a style={{ marginLeft: '12px', left: 0 }} href="mailto:joseph@thepantryapp.io">
                Contact
            </a>
            <p className="allRights">
                © 2021 Pantry.io LLC, All rights reserved.
            </p>
            <a target="_blank" rel="noopener noreferrer" style={{ marginRight: '12px', right: 0 }} href="https://www.iubenda.com/privacy-policy/56174881">
                Privacy
            </a>
        </div>
    )
}

export default Footer;
