// General Imports
import { Link } from 'react-router-dom'

// Style Import
import '../Styles/styles.scss'

// SVG Imports
import Logo from '../SVGs/Logo.svg'; 
import DownloadOnTheAppStore from '../SVGs/DownloadOnTheAppStore.svg';
import GetItOnGooglePlay from '../SVGs/GetItOnGooglePlay.svg';

// Component Imports
import Footer from '../Components/Footer';

// PNG Imports
import WelcomeiPhone from '../PNGs/WelcomeiPhoneScreenshot.png';

const Welcome = () => {
    return (
      <div className="background">
        <div className="welcomeDiv">
          <div className="leftDiv">
            <div style={{ height: '195px' }} className="threeDivs">
              <Link to="/">
                <img className="logoWelcome" src={Logo} alt="logo" />
              </Link>
            </div>
            <div style={{ height: '265px' }} className="threeDivs">
              <div className="textDiv">
                <p className="textTitle">
                  The go-to pantry solution.
                </p>
                <p className="textBody">
                  You can leverage Pantry.io's key features to keep track of what you have in your pantry today!
                </p>
              </div>
            </div>
            <div style={{ height: '230px' }} className="threeDivs">
              <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/pantry-io/id1568582530?ign-itsct=apps_box_link&ign-itscg=30200">
                <img style={{ marginRight: '12px' }} className="downloadIcons" src={DownloadOnTheAppStore} alt='Download on the app store' />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.josephxlarapantry.io&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img className="downloadIcons" src={GetItOnGooglePlay} alt='Download on the google play store' />
              </a>
            </div>
          </div>
          <div className="rightDiv">
            <img className="iPhone" src={WelcomeiPhone} alt="Your Pantry, easier." />
          </div>
        </div>
        <div className="mobileView">
          <Link to="/">
            <img style={{ height: '64px' }} className="logoWelcome" src={Logo} alt="logo" />
          </Link>
          <div className="textDiv">
            <p className="textT">
              The go-to pantry solution.
            </p>
            <p className="textB">
              You can leverage Pantry.io's key features to keep track of what you have in your pantry today!
            </p>
          </div>
          <div className="imgDiv">
            <div className="iPhone2Div">
              <img className="iPhone2" src={WelcomeiPhone} alt="Your Pantry, easier." />
            </div>
            <div className="downloadDiv">
              <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/pantry-io/id1568582530?ign-itsct=apps_box_link&ign-itscg=30200">
                <img style={{ marginRight: '12px' }} className="download" src={DownloadOnTheAppStore} alt='Download on the app store' />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.josephxlarapantry.io&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img className="download" src={GetItOnGooglePlay} alt='Download on the google play store' />
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
}

export default Welcome;