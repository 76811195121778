// General Imports
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Style Import
import './Styles/styles.scss'

// Page Imports
import Welcome from './Pages/Welcome';

// Context Import
import Provider from './Context';

function App() {
  return (
    <Router>
      <Provider>
        <Switch>
          <div style={
            { 
              height: '100vh', 
              width: '100%', 
              justifyContent: 'center', 
              display: 'flex' 
            }
          }>
            <Route exact path='/' component={Welcome} />
          </div>
        </Switch>
      </Provider>
    </Router>
  );
}

export default App;
